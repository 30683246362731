import React from 'react';
import { Button, Container, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  AiOutlineArrowRight,
  AiOutlineIdcard,
  AiOutlineUser,
} from 'react-icons/ai';

interface FormProps {
  assembleiaId: string;
  onSubmit: (data: { cpf: string; funcional: string | undefined }) => void;
}

export default function FormFuncional({ onSubmit }: FormProps) {
  const form = useForm({
    initialValues: {
      cpf: '',
      funcional: '',
    },
    validate: {
      cpf: (value) => (/^\d{11}$/.test(value) ? null : 'CPF Inválido'),
      funcional: (value) =>
        /^\d{5,8}$/.test(value) ? null : 'Funcional Inválido',
    },
  });

  return (
    <Container w="100%" p="lg">
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <TextInput
            label="CPF (Ex: 07700011122)"
            icon={<AiOutlineIdcard />}
            required
            withAsterisk
            {...form.getInputProps('cpf')}
            size="lg"
            error={form.errors.cpf}
          />
          <TextInput
            label={
              process.env.REACT_APP_LABEL_RG_FUNCIONAL ||
              'Funcional (Ex: 09876543)'
            }
            icon={<AiOutlineUser />}
            {...form.getInputProps('funcional')}
            size="lg"
            required
            error={form.errors.funcional}
          />
          <Button
            rightIcon={<AiOutlineArrowRight />}
            size="lg"
            color="green"
            type="submit"
          >
            VALIDAR E ACESSAR
          </Button>
        </Stack>
      </form>
    </Container>
  );
}
